.pageWrapper {
  min-height: 100vh;
  position: relative;
}
.curveContainer {
  visibility: visible;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100px;
  padding-top: 100px;
  background: var(--clr-yellow);
}
.curve {
  position: absolute;
  height: 4rem;
  width: 100%;
  bottom: 0;
  text-align: center;
}

.curve::before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100% 50%;
  width: 55%;
  height: 100%;
  transform: translate(85%, 60%);
  background-color: var(--clr-primary-9);
  z-index: 1;
}

.curve::after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100% 50%;
  width: 55%;
  height: 100%;
  background-color: var(--clr-yellow);
  transform: translate(-4%, 40%);
  z-index: 2;
}

.landing {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.landingContent {
  flex-basis: 50%;
  background-color: transparent;

  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  text-align: center;
  z-index: 10;
}

.text {
  text-transform: uppercase;
}
.title {
  margin: 0;
}

.info {
  margin: 0.5rem 0;
  line-height: 2.25;
}

.btnContainer {
  margin-top: 0.5rem;
  display: flex;
  gap: 1rem;
}

.gitHubBtn,
.linkedInBtn {
  border-radius: 10px;
}

.gitHubBtn {
  background-color: var(--clr-yellow);
  color: var(--clr-primary-1);
}

.linkedInBtn {
  background-color: transparent;
  color: var(--clr-primary-1);

  border: 1px solid var(--clr-primary-1);
}

.imgContainer {
  width: 100%;
  z-index: 10;
}

.img {
  width: 100%;
  object-fit: contain;
}

@media (min-width: 600px) {
  .curveContainer {
    min-height: 150px;
  }
  .curve {
    height: 8rem;
  }

  .landingSection {
    display: flex;
    justify-content: center;
    height: calc(100vh - 4rem - 128px);
  }
  .landing {
    position: relative;
    min-height: 100vh;

    min-height: initial;
    margin: 0;
  }
  .landingContent {
    padding-bottom: 2rem;
  }

  .imgContainer {
    text-align: center;
    width: 100%;
  }

  .img {
    width: 60%;
  }
}

@media (min-width: 768px) {
  .scrollContainer {
    display: none;
  }
}
@media (min-width: 900px) {
  .landing {
    flex-direction: row;
    gap: 1rem;
  }

  .landingContent {
    flex-basis: 55%;
    gap: 2rem;
    padding: 0;
    align-items: flex-start;
    text-align: start;
  }
  .imgContainer {
    flex-basis: 45%;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0;
  }

  .img {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .landingContent {
    flex-basis: 45%;
  }
  .imgContainer {
    flex-basis: 55%;
  }
}
