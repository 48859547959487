nav {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 4rem;
  background-color: var(--clr-yellow);
  z-index: 9999;
}
.navContainer {
  display: flex;
  align-items: center;
  height: 4rem;
}

.navHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  width: 100%;
}
.logo {
  height: 40%;
  object-fit: contain;
}

.navLinks {
  display: none;
}

.link {
  color: var(--clr-primary-1);
  background-color: transparent;
  border: none;
  text-transform: capitalize;
}

// nav {
//   position: fixed;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   width: 100vw;
//   height: 6rem;
//   background-color: var(--clr-yellow);
//   z-index: 9999;
// }
// .navContainer {
//   display: flex;
//   align-items: center;
//   height: 6rem;
// }

// .navHeader {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   height: 6rem;
//   width: 100%;
// }
// .logo {
//   height: 40%;
//   object-fit: contain;
// }

// .navLinks {
//   display: none;
// }

// .link {
//   color: var(--clr-primary-1);
//   background-color: transparent;
//   border: none;
//   text-transform: capitalize;
// }

@media (min-width: 768px) {
  .logo {
    height: 50%;
    width: 70%;
  }
  .navContainer {
    justify-content: space-between;
  }

  .navHeader {
    width: auto;
  }
  .navLinks {
    flex-basis: 60%;
    display: flex;
    // justify-content: space-between;
    justify-content: space-around;
  }

  .link {
    font-size: 1.25rem;
  }

  .link:hover {
    color: var(--clr-primary-10);
  }

  .btn {
    display: none;
  }
}

@media (min-width: 1200px) {
  .nav {
    display: flex;
    justify-content: center;
  }
}
