.projectsSection {
  min-height: calc(100vh - 4rem);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.btnContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.5rem;
  padding: 1rem 0;
}

.projectBtn {
  width: 175px;
  background-color: var(--clr-primary-10);
  border: transparent;
  text-transform: uppercase;
  border-radius: 0;
  cursor: pointer;
}

.activeBtn {
  background-color: var(--clr-yellow);
}

.projectContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 0;
}

.projectImgContainer {
  height: 250px;
}

.projectImg {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 20px 20px 0 0;
}

.title {
  text-align: center;
  margin: 1rem;

  font-weight: 400;
}
.desc {
  margin: 0;
}

.projectInfoContainer {
  background-color: var(--clr-primary-10);
  border-radius: 0 0 20px 20px;
  height: 80%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: center;

  cursor: pointer;
}

.infoDetails {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
}

.linkContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 0;
}

.link {
  width: 110px;
  padding: 0.25rem;
  border: 1px solid var(--clr-primary-1);
  border-radius: 10px;
  background-color: var(--clr-primary-10);
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.srcLink {
  background-color: var(--clr-yellow);
  border: none;
}

.link:hover,
.projectBtn:hover,
.srcLink:hover {
  background-color: var(--clr-light-yellow);
  color: var(--clr-primary-1);
}

@media (min-width: 670px) {
  .projectsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 3rem;
    padding: 2rem 0;
  }

  .btnContainer {
    padding: 0;
    width: 200px;
    flex-direction: column;
    justify-content: flex-start;
    gap: 2rem;
  }

  .projectBtn {
    margin-bottom: 1rem;
  }

  .projectContainer {
    padding: 0;
  }

  .projectImgContainer {
    height: 250px;
    max-height: 250px;
  }

  .title {
  }

  .projectInfoContainer {
    padding: 1rem 2rem;
  }

  .linkContainer {
    gap: 1rem;
  }

  .link {
    width: 140px;
  }
}

@media (min-width: 900px) {
  .projectsWrapper {
    gap: 5rem;
  }

  .projectBtn {
    width: 200px;
  }
}
