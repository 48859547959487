.contactSection {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 768px) {
  .contactSection {
    min-height: calc(100vh - 8rem);
  }
}
