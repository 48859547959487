:root {
  /* dark shades of primary color*/
  --clr-primary-1: #171717;
  --clr-primary-2: #262626;
  --clr-primary-3: #404040;
  --clr-primary-4: #525252;
  /* primary/main color */
  --clr-primary-5: #858585;
  /* lighter shades of primary color */
  --clr-primary-6: #ab7a5f;
  --clr-primary-7: #c5a491;
  --clr-primary-8: #eaded7;
  --clr-primary-9: #f9faff;
  --clr-primary-10: #ffff;
  --clr-yellow: #fdc435;
  --clr-light-yellow: #ffeb6a;
  --clr-red: rgb(216, 0, 0);
}

// html {
//   scroll-behavior: smooth;
//   scroll-padding-top: 4rem;
// }
html {
  scroll-behavior: smooth;
  scroll-padding-top: 2rem;
}
*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background: var(--clr-primary-9);
  color: var(--clr-primary-1);
  line-height: 1.5;
  font-size: 0.875rem;
}

textarea,
input {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
  color: var(--clr-primary-1);
}
hr {
  border: none;
  border-top: 1px solid var(--clr-primary-1);
  margin: 1rem 0;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: "General Sans", sans-serif;
  // text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 2.5rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.5rem;
}
h4 {
  font-size: 1.15rem;
}
h5 {
  font-size: 1rem;
}
h6 {
  font-size: 0.875rem;
}
p {
  font-size: 1rem;
  margin-bottom: 1rem;
  color: var(--clr-primary-2);
}

button {
  font-size: 1rem;
  text-transform: capitalize;
}

.section {
  width: 85vw;
  margin: 0 auto;
  padding: 2rem 0rem;
}

.page {
  min-height: calc(100vh - 4rem);
}

.textCenter {
  text-align: center;
}
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.loading {
  width: 6rem;
  height: 6rem;
  margin: 0 auto;
  border-radius: 50%;
  border: 4px solid var(--clr-primary-5);
  border-top-color: var(--clr-primary-1);
  animation: spinner 0.6s linear infinite;
}
.loadingWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

button {
  font-family: inherit;
}

.btn {
  text-transform: uppercase;
  background: var(--clr-yellow);
  color: var(--clr-primary-1);
  padding: 0.375rem 1.5rem;
  display: inline-block;
  font-weight: 400;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  border-color: transparent;
  font-size: 1rem;
}
.btn:hover {
  color: var(--clr-primary-1);
  background: var(--clr-light-yellow);
}

.header {
  padding: 1rem 0;
}

.title {
  text-align: center;
  margin: 1rem 0;
}

.underline {
  width: 6rem;
  height: 0.25rem;
  background: var(--clr-yellow);
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 768px) {
  // h1 {
  //   font-size: 3.5rem;
  // }
  // h2 {
  //   font-size: 2.5rem;
  // }
  // h3 {
  //   font-size: 2rem;
  // }
  // h4 {
  //   font-size: 1.75rem;
  // }
  // h5 {
  //   font-size: 1.5rem;
  // }
  // h6 {
  //   font-size: 1.15rem;
  // }

  // body {
  //   font-size: 1rem;
  // }
  // h1,
  // h2,
  // h3,
  // h4 {
  //   line-height: 1;
  // }
  // p {
  //   font-size: 1.05rem;
  // }

  // .btn {
  //   font-size: 1rem;
  //   padding: 0.5rem 1.75rem;
  // }

  .section {
    width: 75vw;
  }
}

@media screen and (min-width: 1200px) {
  // h1 {
  //   font-size: 4rem;
  // }
  // h2 {
  //   font-size: 3, 5rem;
  // }
  // h3 {
  //   font-size: 2.5rem;
  // }
  // h4 {
  //   font-size: 2rem;
  // }
  // h5 {
  //   font-size: 1.75rem;
  // }
  // h6 {
  //   font-size: 1.25rem;
  // }

  // body {
  //   font-size: 1rem;
  // }
  // h1,
  // h2,
  // h3,
  // h4 {
  //   line-height: 1;
  // }
  // p {
  //   font-size: 1.15rem;
  // }

  .section {
    max-width: 900px;
  }
}
